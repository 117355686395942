module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Link On-Demand","short_name":"Link On-Demand","description":"On-demand server helping users map their databases to MI & CapIQ.","start_url":"/","background_color":"#eaf8fc","theme_color":"#eaf8fc","display":"browser","icon":"src/images/kensho-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"06c6b3374472ca4a74b7c9773d69ead2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":[]}},"gatsby-remark-autolink-headers","gatsby-remark-emojis",{"resolve":"/app/plugins/gatsby-remark-unprefix-links/index.js"}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/app"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
