import * as React from 'react'
import Highlight, {defaultProps, Language} from 'prism-react-renderer'

import styles from '../styles'

interface ChildrenProps extends React.HTMLProps<HTMLPreElement> {
  children: string
}

interface CodeBlockProps {
  children: React.ReactElement<ChildrenProps>
}

export default function CodeBlock({children}: CodeBlockProps): JSX.Element {
  const cls = children.props.className || ''
  const langMatch = cls.match(/language-(?<lang>.*)/)
  const language: Language = langMatch?.groups?.lang || ''

  return (
    <Highlight
      Prism={defaultProps.Prism}
      theme={styles.code.theme}
      code={children.props.children}
      language={language}
    >
      {({className, style, tokens, getLineProps, getTokenProps}) => (
        <pre className={className} style={style}>
          {tokens.map((line, i) => {
            // don't render last line if it's empty
            if (i === tokens.length - 1 && (!line.length || (line.length === 1 && line[0].empty)))
              return null
            return (
              <div {...getLineProps({line, key: i})}>
                {line.map((token, key) => (
                  <span {...getTokenProps({token, key})} />
                ))}
              </div>
            )
          })}
        </pre>
      )}
    </Highlight>
  )
}
